import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  theme: "lightTheme",
  color: "#ffffff",
  loader: true,
  resizeWidth: null,
  resizeHeight: null
}

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_THEME": {
      const bgColors = [
        "#17257a",
        "#426A5A",
        "#1E1E24",
        "#6D326D",
        "#2F4550",
        "#C18C5D",
        "#495867",
      ]
      function getRandomInt(max) {
        return Math.floor(Math.random() * max)
      }
      let randColor = bgColors[getRandomInt(bgColors.length)]
      return {
        ...state,
        theme: state.theme === "lightTheme" ? "randTheme" : "lightTheme",
        color: state.color === "#ffffff" ? randColor : "#ffffff",
      }
    }

    case "LOADER_COMPLETE": {
      return {
        ...state,
        loader: false
      }
    }

    case "RESIZE_TRIGGERED": {
      return {
        ...state,
        resizeWidth: window.innerWidth,
        resizeHeight: window.innerHeight
      }
    }

    default:
      break
  }
}



const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
